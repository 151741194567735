let defaultExamples = [
  {
    name: 'api-with-examples',
    url: 'https://raw.githubusercontent.com/open-rpc/examples/master/service-descriptions/api-with-examples-openrpc.json', //tslint:disable-line
  },
  {
    name: 'link-example',
    url: 'https://raw.githubusercontent.com/open-rpc/examples/master/service-descriptions/link-example-openrpc.json', //tslint:disable-line
  },
  {
    name: 'params-by-name-petstore',
    url: 'https://raw.githubusercontent.com/open-rpc/examples/master/service-descriptions/params-by-name-petstore-openrpc.json', //tslint:disable-line
  },
  {
    name: 'petstore-expanded',
    url: 'https://raw.githubusercontent.com/open-rpc/examples/master/service-descriptions/petstore-expanded-openrpc.json', //tslint:disable-line
  },
  {
    name: 'petstore',
    url: 'https://raw.githubusercontent.com/open-rpc/examples/master/service-descriptions/petstore-openrpc.json', //tslint:disable-line
  },
  {
    name: 'simple-math',
    url: 'https://raw.githubusercontent.com/open-rpc/examples/master/service-descriptions/simple-math-openrpc.json', //tslint:disable-line
  },
  {
    name: 'empty-document',
    url: 'https://raw.githubusercontent.com/open-rpc/examples/master/service-descriptions/empty-openrpc.json', //tslint:disable-line
  },
];

if (process.env.REACT_APP_EXAMPLE_DOCUMENTS_DROPDOWN_LIST) {
  defaultExamples = JSON.parse(process.env.REACT_APP_EXAMPLE_DOCUMENTS_DROPDOWN_LIST);
}

export default defaultExamples;
